import * as React from "react"
import { Box, Flex, Image, Paragraph, Divider } from "theme-ui"
import headingImage from "../images/img12.svg"
import placeholderLogo from "../images/project_logo_placeholder.svg"
import separator from "../images/opensource-separator.svg"
import shape from "../images/purple-shape.svg"
import lifecycleImg from "../images/lifecycle.svg"
import { graphql } from "gatsby"
import {
  CenteredSectionTitle,
  PaddedContent,
  PageHeading,
  PageWrapper,
  PageHeadingParagraph,
} from "../components/modern/layout"
import { CardSet } from "../components/modern/cards"
import { SimpleButton } from "../components/modern/buttons"

export const query = graphql`
  query {
    allProjectsYaml {
      nodes {
        projects {
          active
          description
          name
          subtitle
          logo {
            publicURL
          }
          slug
          upstream
          tags
          url
        }
        featured {
          opensource {
            flagship
            slug
          }
        }
      }
    }
  }
`

// Projects as they appear in the YAML file
type Project = {
  name: string
  slug: string
  subtitle?: string
  // `upstream` is `true` if we contribute to an upstream project or `false` if a home project
  upstream?: boolean
  active?: boolean
  logo?: { publicURL: string }
  tags?: string[]
  description: string
  url: string
}

// Featured projects as they are mentionned in the YAML file
type FeaturedProject = {
  slug: string
  flagship?: boolean
}

// This type describes a `FeaturedProject` in which the slug has been resolved.
// The `flagshipIndex`, when it exists, associate a number to each flagship
// project, starting from 0. This is used to associate different colors when
// rendering the flagship cards.
type ResolvedFeaturedProject = {
  project: Project
  flagshipIndex?: number // Used to change the color of flagship cards
}

// This component is the lifecycle component, displaying the different phases of the software development lifecycle
const Lifecycle: React.FC<{ image: string }> = ({ image }) => (
  <PaddedContent customSx={{ position: `relative` }}>
    <Flex
      sx={{
        textAlign: `center`,
        flexDirection: `column`,
        alignItems: `center`,
        "::before": {
          content: `''`,
          position: `absolute`,
          top: `-60px`,
          left: `0`,
          background: `url(${image}) no-repeat`,
          backgroundSize: `cover`,
          display: [`none`, null, `inline-block`],
          height: `230px`,
          width: `240px`,
          zIndex: `1`,
        },
      }}
    >
      <Box
        sx={{
          position: `relative`,
          zIndex: `2`,
        }}
      >
        <CenteredSectionTitle title="Matching the Software Development Lifecycle" />
        <Paragraph
          sx={{
            fontSize: [`1.2em`, `1.6em`],
            maxWidth: `37em`,
            margin: `auto`,
          }}
        >
          We bring together incredibly talented engineers, who are well
          respected within their communities of practice to contribute something
          interesting and novel to each step in the SDLC. Alongside the track
          record of our consulting organization, that means we bring
          unparalleled skill to your most pressing challenges.
        </Paragraph>
      </Box>
      <Box
        sx={{
          width: `min(100%, 90em)`,
          marginTop: `6em`,
          marginBottom: `3em`,
        }}
      >
        <a href={lifecycleImg}>
          <Image src={lifecycleImg} />
        </a>
      </Box>
      <Image
        src={separator}
        sx={{
          maxWidth: `75em`,
          marginY: `3em`,
        }}
      />
    </Flex>
  </PaddedContent>
)

// This component is the card set of featured projects, where flagship projects
// are given a different theme that stands out
const FeaturedProjects: React.FC<{ projects: ResolvedFeaturedProject[] }> = ({
  projects,
}) => {
  const flagshipColors = [`green`, `orange`, `blue`, `red`]
  return (
    <Box
      sx={{
        maxWidth: `100em`,
        margin: `auto`,
      }}
    >
      <CardSet
        cards={projects.map(({ project, flagshipIndex }) => ({
          slug: project.slug,
          content: {
            title: project.name,
            subtitle: project.subtitle,
            tags: project.tags,
            icon: {
              src: project.logo ? project.logo.publicURL : placeholderLogo,
            },
            description: project.description,
            button: {
              label: `Read more`,
              src: project.url,
            },
          },
          theme:
            flagshipIndex !== undefined
              ? {
                  size: `double`,
                  color: flagshipColors[flagshipIndex % flagshipColors.length],
                  extraPadding: true,
                  center: true,
                  backgroundIcon: true,
                }
              : {},
        }))}
      />
    </Box>
  )
}

// This is the component of a project in the "Discover more" section
const Project: React.FC<{ project: Project }> = ({ project }) => (
  <a href={project.url}>
    <Flex
      sx={{
        marginY: `2em`,
        alignItems: `flex-start`,
        gap: `2em`,
      }}
    >
      <Image
        src={project.logo ? project.logo.publicURL : placeholderLogo}
        sx={{
          width: `5em`,
          height: `5em`,
          objectFit: `contain`,
          flexGrow: 0,
          flexShrink: 0,
          padding: `.6em`,
          border: `solid 1px black`,
          borderRadius: `1.2em`,
        }}
      />
      <Box
        sx={{
          flexShrink: 1,
        }}
      >
        <Paragraph
          sx={{
            fontSize: `1.2em`,
            fontWeight: `bold`,
            color: `black`,
          }}
        >
          {project.name}
        </Paragraph>
        {project.subtitle ? (
          <Paragraph
            sx={{
              fontSize: `1em`,
              color: `black`,
            }}
          >
            {project.subtitle}
          </Paragraph>
        ) : null}
        {project.tags ? (
          <Paragraph
            sx={{
              color: `gray`,
            }}
          >
            {project.tags.join(`, `)}
          </Paragraph>
        ) : null}
        <Paragraph
          sx={{
            marginTop: `1em`,
            color: `black`,
          }}
        >
          {project.description}
        </Paragraph>
      </Box>
    </Flex>
  </a>
)

// This component is a list of projects in the "discover more" section
const Projects: React.FC<{ projects: Project[] }> = ({ projects }) => (
  <Box
    sx={{
      maxWidth: `70em`,
      marginX: `auto`,
      marginBottom: `4em`,
    }}
  >
    {projects
      .map(project => <Project project={project} key={project.slug} />)
      .flatMap((project, i) => [<Divider key={i} />, project])
      .slice(1)}
  </Box>
)

// The pre-footer containing the link to our GitHub page
const AllProjectsLink: React.FC<{ color?: string }> = ({ color }) => (
  <PaddedContent background={color}>
    <Box
      sx={{
        textAlign: `center`,
      }}
    >
      <Paragraph
        sx={{
          color: `white`,
          fontSize: `1.3em`,
          marginBottom: `2em`,
        }}
      >
        Discover the rest of our projects
      </Paragraph>
      <SimpleButton
        label="Tweag's GitHub page"
        icomoonIcon=""
        url="https://github.com/tweag"
        theme={{
          textColor: color,
          backgroundColor: `white`,
          size: `big`,
        }}
      />
    </Box>
  </PaddedContent>
)

const OpenSource = ({ data }) => {
  // Fetch raw data from queries of the YAML files
  const projects: Project[] = data.allProjectsYaml.nodes[0].projects
  const featuredProjects: FeaturedProject[] =
    data.allProjectsYaml.nodes[0].featured.opensource

  const [numDiscoverMoreProjectsToShow, setNumDiscoverMoreProjectsToShow] =
    React.useState(5)

  const loadMoreDiscoverMoreProjects = () => {
    setNumDiscoverMoreProjectsToShow(prevNum => prevNum + 5)
  }

  // Resolve projects from their slugs
  const accumulate = ({ res, i }, featuredProject) => ({
    res: [
      ...res,
      {
        project: projects.find(
          project => project.slug === featuredProject.slug
        ),
        flagshipIndex: featuredProject.flagship ? i : undefined,
      },
    ],
    i: featuredProject.flagship ? i + 1 : i,
  })
  const resolvedFeaturedProjects = featuredProjects.reduce(accumulate, {
    res: [],
    i: 0,
  }).res
  const discoverMoreProjects = projects.filter(
    project =>
      !featuredProjects.find(
        featuredProject => featuredProject.slug === project.slug
      )
  )

  // Rendered component
  return (
    <PageWrapper title="Open Source" pathname="/opensource">
      <PageHeading title="Open Source Mindset" imageSrc={headingImage}>
        <PageHeadingParagraph>
          Tweag&apos;s goal is to democratize state-of-the-art technologies by
          promoting or developing new open-source frameworks, programming
          languages, and tools.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          We strive to improve developer experience (DX) and data-centric
          workflows (MLOps). Our engineers are the creators of Nickel, a generic
          configuration language, and among the top contributors to Nix,
          Haskell, and Bazel.
        </PageHeadingParagraph>
        <PageHeadingParagraph>
          We execute the open-source mindset by taking a community-centric
          approach, addressing bottlenecks to community growth, and engaging in
          its governance.
        </PageHeadingParagraph>
      </PageHeading>
      <Lifecycle image={shape} />
      <PaddedContent>
        <FeaturedProjects projects={resolvedFeaturedProjects} />
      </PaddedContent>
      <CenteredSectionTitle title="Discover more" />
      <PaddedContent>
        <Projects
          projects={discoverMoreProjects.slice(
            0,
            numDiscoverMoreProjectsToShow
          )}
        />
        {discoverMoreProjects.length > numDiscoverMoreProjectsToShow && (
          <Box
            sx={{
              textAlign: `center`,
            }}
          >
            <SimpleButton
              label="Load More"
              icomoonIcon=""
              onClick={loadMoreDiscoverMoreProjects}
            />
          </Box>
        )}
      </PaddedContent>
      <AllProjectsLink color="orange" />
    </PageWrapper>
  )
}

export default OpenSource
